import React from 'react';

const PhoneIconBlue = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none" {...props}>
    <path d="M6.61326 7.80095C7.13191 8.86129 7.99141 9.71689 9.0541 10.2307C9.13185 10.2675 9.21785 10.2835 9.30365 10.277C9.38944 10.2705 9.47207 10.2418 9.54339 10.1937L11.1081 9.15028C11.1773 9.10414 11.2569 9.07598 11.3398 9.06838C11.4226 9.06077 11.506 9.07394 11.5825 9.10671L14.5098 10.3613C14.6092 10.4035 14.6923 10.4769 14.7463 10.5705C14.8004 10.664 14.8227 10.7726 14.8097 10.8798C14.7172 11.6038 14.3639 12.2693 13.8161 12.7516C13.2682 13.2339 12.5634 13.5 11.8335 13.5C9.57916 13.5 7.41715 12.6045 5.82309 11.0104C4.22903 9.41635 3.3335 7.25434 3.3335 5C3.33353 4.27011 3.59962 3.56526 4.08192 3.01742C4.56423 2.46959 5.22968 2.11634 5.95368 2.02381C6.06094 2.01083 6.16951 2.03306 6.26304 2.08715C6.35657 2.14124 6.42998 2.22426 6.47223 2.3237L7.72788 5.25357C7.76036 5.32935 7.7736 5.41199 7.76641 5.49413C7.75922 5.57626 7.73183 5.65535 7.68668 5.72434L6.6469 7.31307C6.59958 7.38454 6.5716 7.46706 6.56571 7.55257C6.55981 7.63809 6.5762 7.72367 6.61326 7.80095V7.80095Z" stroke="#00114A" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
)
const PhoneIconwhite = (props) => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g id="Phone">
    <path id="Vector" d="M5.77977 7.80095C6.29842 8.86129 7.15792 9.71689 8.2206 10.2307C8.29835 10.2675 8.38436 10.2835 8.47015 10.277C8.55595 10.2705 8.63857 10.2418 8.7099 10.1937L10.2746 9.15028C10.3438 9.10414 10.4235 9.07598 10.5063 9.06838C10.5891 9.06077 10.6725 9.07394 10.749 9.10671L13.6763 10.3613C13.7757 10.4035 13.8588 10.4769 13.9128 10.5705C13.9669 10.664 13.9892 10.7726 13.9762 10.8798C13.8837 11.6038 13.5304 12.2693 12.9826 12.7516C12.4347 13.2339 11.7299 13.5 11 13.5C8.74566 13.5 6.58365 12.6045 4.98959 11.0104C3.39553 9.41635 2.5 7.25434 2.5 5C2.50004 4.27011 2.76612 3.56526 3.24843 3.01742C3.73073 2.46959 4.39618 2.11634 5.12019 2.02381C5.22745 2.01083 5.33602 2.03306 5.42955 2.08715C5.52307 2.14124 5.59649 2.22426 5.63873 2.3237L6.89439 5.25357C6.92687 5.32935 6.9401 5.41199 6.93291 5.49413C6.92572 5.57626 6.89833 5.65535 6.85318 5.72434L5.81341 7.31307C5.76608 7.38454 5.7381 7.46706 5.73221 7.55257C5.72631 7.63809 5.7427 7.72367 5.77977 7.80095V7.80095Z" stroke="#B2B5BF" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    </svg>   
)
const CallIconwhite = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none" {...props}>
    <path d="M5.94627 7.80095C6.46492 8.86129 7.32442 9.71689 8.38711 10.2307C8.46486 10.2675 8.55086 10.2835 8.63666 10.277C8.72245 10.2705 8.80508 10.2418 8.8764 10.1937L10.4411 9.15028C10.5103 9.10414 10.59 9.07598 10.6728 9.06838C10.7556 9.06077 10.839 9.07394 10.9155 9.10671L13.8428 10.3613C13.9422 10.4035 14.0253 10.4769 14.0794 10.5705C14.1334 10.664 14.1557 10.7726 14.1427 10.8798C14.0502 11.6038 13.6969 12.2693 13.1491 12.7516C12.6012 13.2339 11.8964 13.5 11.1665 13.5C8.91216 13.5 6.75016 12.6045 5.1561 11.0104C3.56204 9.41635 2.6665 7.25434 2.6665 5C2.66654 4.27011 2.93262 3.56526 3.41493 3.01742C3.89723 2.46959 4.56269 2.11634 5.28669 2.02381C5.39395 2.01083 5.50252 2.03306 5.59605 2.08715C5.68958 2.14124 5.76299 2.22426 5.80523 2.3237L7.06089 5.25357C7.09337 5.32935 7.1066 5.41199 7.09941 5.49413C7.09222 5.57626 7.06483 5.65535 7.01968 5.72434L5.97991 7.31307C5.93259 7.38454 5.90461 7.46706 5.89871 7.55257C5.89282 7.63809 5.9092 7.72367 5.94627 7.80095V7.80095Z" stroke="white" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
)
const EmailIconWhite = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
    <path d="M2 3.5H14V12C14 12.1326 13.9473 12.2598 13.8536 12.3536C13.7598 12.4473 13.6326 12.5 13.5 12.5H2.5C2.36739 12.5 2.24021 12.4473 2.14645 12.3536C2.05268 12.2598 2 12.1326 2 12V3.5Z" stroke="white" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M14 3.5L8 9L2 3.5" stroke="white" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
)
const EnvelopeIconBlue = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
    <path d="M2 3.5H14V12C14 12.1326 13.9473 12.2598 13.8536 12.3536C13.7598 12.4473 13.6326 12.5 13.5 12.5H2.5C2.36739 12.5 2.24021 12.4473 2.14645 12.3536C2.05268 12.2598 2 12.1326 2 12V3.5Z" stroke="#00114A" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M14 3.5L8 9L2 3.5" stroke="#00114A" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
)
const EnvelopeIconWhite = (props) => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g id="EnvelopeSimple">
    <path id="Vector" d="M2 3.5H14V12C14 12.1326 13.9473 12.2598 13.8536 12.3536C13.7598 12.4473 13.6326 12.5 13.5 12.5H2.5C2.36739 12.5 2.24021 12.4473 2.14645 12.3536C2.05268 12.2598 2 12.1326 2 12V3.5Z" stroke="#B2B5BF" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
    <path id="Vector_2" d="M14 3.5L8 9L2 3.5" stroke="#B2B5BF" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    </svg>
    
)
const WhatsAppIconWhite = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none" {...props}>
    <path d="M3.17231 11.0624C2.42685 9.8057 2.16578 8.32012 2.43813 6.88459C2.71048 5.44906 3.49751 4.16233 4.65143 3.26602C5.80536 2.3697 7.2468 1.92547 8.70508 2.01672C10.1634 2.10798 11.5382 2.72845 12.5714 3.76162C13.6045 4.7948 14.225 6.16961 14.3163 7.62789C14.4075 9.08618 13.9633 10.5276 13.067 11.6815C12.1707 12.8355 10.884 13.6225 9.44844 13.8949C8.01292 14.1672 6.52734 13.9062 5.27067 13.1607L5.27068 13.1607L3.1984 13.7527C3.11266 13.7772 3.02193 13.7784 2.93561 13.756C2.8493 13.7336 2.77053 13.6886 2.70748 13.6255C2.64443 13.5625 2.59938 13.4837 2.57702 13.3974C2.55465 13.3111 2.55577 13.2203 2.58027 13.1346L3.17235 11.0623L3.17231 11.0624Z" stroke="white" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M9.84011 11.5C9.18231 11.5009 8.53079 11.3721 7.92287 11.1208C7.31496 10.8695 6.76261 10.5007 6.29747 10.0355C5.83234 9.5704 5.46355 9.01805 5.21225 8.41013C4.96095 7.80222 4.83208 7.1507 4.83301 6.49289C4.8345 6.02988 5.01958 5.58635 5.34765 5.25962C5.67572 4.93288 6.11999 4.74961 6.58301 4.75V4.75C6.65895 4.75 6.73355 4.77008 6.79923 4.8082C6.86492 4.84632 6.91936 4.90112 6.95704 4.96706L7.68773 6.24577C7.7318 6.32288 7.75453 6.41034 7.75358 6.49915C7.75264 6.58797 7.72805 6.67492 7.68236 6.75108L7.09554 7.72911C7.39744 8.3991 7.93391 8.93557 8.6039 9.23747V9.23747L9.58192 8.65066C9.65808 8.60496 9.74504 8.58038 9.83385 8.57943C9.92266 8.57849 10.0101 8.60122 10.0872 8.64528L11.3659 9.37597C11.4319 9.41365 11.4867 9.46809 11.5248 9.53377C11.5629 9.59946 11.583 9.67406 11.583 9.75V9.75C11.5817 10.2125 11.3979 10.6558 11.0715 10.9835C10.7451 11.3112 10.3026 11.4968 9.84011 11.5V11.5Z" stroke="white" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
)
const WhatsAppIconBlue = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none" {...props}>
    <path d="M3.50629 11.0624C2.76083 9.8057 2.49976 8.32012 2.77211 6.88459C3.04446 5.44906 3.83149 4.16233 4.98542 3.26602C6.13934 2.3697 7.58078 1.92547 9.03906 2.01672C10.4973 2.10798 11.8722 2.72845 12.9053 3.76162C13.9385 4.7948 14.559 6.16961 14.6503 7.62789C14.7415 9.08618 14.2973 10.5276 13.401 11.6815C12.5047 12.8355 11.218 13.6225 9.78243 13.8949C8.3469 14.1672 6.86132 13.9062 5.60465 13.1607L5.60467 13.1607L3.53238 13.7527C3.44664 13.7772 3.35591 13.7784 3.2696 13.756C3.18328 13.7336 3.10451 13.6886 3.04146 13.6255C2.97841 13.5625 2.93337 13.4837 2.911 13.3974C2.88864 13.3111 2.88976 13.2203 2.91426 13.1346L3.50634 11.0623L3.50629 11.0624Z" stroke="#00114A" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M10.1741 11.5C9.51629 11.5009 8.86477 11.3721 8.25686 11.1208C7.64895 10.8695 7.0966 10.5007 6.63146 10.0355C6.16632 9.5704 5.79754 9.01805 5.54624 8.41013C5.29494 7.80222 5.16606 7.1507 5.167 6.49289C5.16849 6.02988 5.35356 5.58635 5.68163 5.25962C6.0097 4.93288 6.45397 4.74961 6.91699 4.75V4.75C6.99294 4.75 7.06753 4.77008 7.13322 4.8082C7.1989 4.84632 7.25335 4.90112 7.29102 4.96706L8.02171 6.24577C8.06578 6.32288 8.08851 6.41034 8.08757 6.49915C8.08662 6.58797 8.06204 6.67492 8.01634 6.75108L7.42952 7.72911C7.73143 8.3991 8.2679 8.93557 8.93788 9.23747V9.23747L9.91591 8.65066C9.99207 8.60496 10.079 8.58038 10.1678 8.57943C10.2566 8.57849 10.3441 8.60122 10.4212 8.64528L11.6999 9.37597C11.7659 9.41365 11.8207 9.46809 11.8588 9.53377C11.8969 9.59946 11.917 9.67406 11.917 9.75V9.75C11.9157 10.2125 11.7318 10.6558 11.4055 10.9835C11.0791 11.3112 10.6366 11.4968 10.1741 11.5V11.5Z" stroke="#00114A" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
)
const WhatsAppIconGreen = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
    <path d="M2.8393 11.0624C2.09384 9.8057 1.83277 8.32012 2.10512 6.88459C2.37747 5.44906 3.1645 4.16233 4.31843 3.26602C5.47235 2.3697 6.91379 1.92547 8.37207 2.01672C9.83036 2.10798 11.2052 2.72845 12.2384 3.76162C13.2715 4.7948 13.892 6.16961 13.9833 7.62789C14.0745 9.08618 13.6303 10.5276 12.734 11.6815C11.8377 12.8355 10.551 13.6225 9.11544 13.8949C7.67991 14.1672 6.19433 13.9062 4.93766 13.1607L4.93767 13.1607L2.86539 13.7527C2.77965 13.7772 2.68892 13.7784 2.60261 13.756C2.51629 13.7336 2.43752 13.6886 2.37447 13.6255C2.31142 13.5625 2.26638 13.4837 2.24401 13.3974C2.22164 13.3111 2.22277 13.2203 2.24726 13.1346L2.83935 11.0623L2.8393 11.0624Z" stroke="#27D045" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M9.50711 11.5C8.8493 11.5009 8.19778 11.3721 7.58987 11.1208C6.98195 10.8695 6.4296 10.5007 5.96447 10.0355C5.49933 9.5704 5.13054 9.01805 4.87924 8.41013C4.62794 7.80222 4.49907 7.1507 4.50001 6.49289C4.50149 6.02988 4.68657 5.58635 5.01464 5.25962C5.34271 4.93288 5.78698 4.74961 6.25 4.75V4.75C6.32595 4.75 6.40054 4.77008 6.46623 4.8082C6.53191 4.84632 6.58635 4.90112 6.62403 4.96706L7.35472 6.24577C7.39879 6.32288 7.42152 6.41034 7.42057 6.49915C7.41963 6.58797 7.39504 6.67492 7.34935 6.75108L6.76253 7.72911C7.06444 8.3991 7.6009 8.93557 8.27089 9.23747V9.23747L9.24892 8.65066C9.32508 8.60496 9.41203 8.58038 9.50084 8.57943C9.58966 8.57849 9.67712 8.60122 9.75423 8.64528L11.0329 9.37597C11.0989 9.41365 11.1537 9.46809 11.1918 9.53377C11.2299 9.59946 11.25 9.67406 11.25 9.75V9.75C11.2487 10.2125 11.0648 10.6558 10.7385 10.9835C10.4121 11.3112 9.96959 11.4968 9.50711 11.5V11.5Z" stroke="#27D045" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
)
const PhoneIconGrey = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
    <path d="M5.77977 7.80095C6.29842 8.86129 7.15792 9.71689 8.2206 10.2307C8.29835 10.2675 8.38436 10.2835 8.47015 10.277C8.55595 10.2705 8.63857 10.2418 8.7099 10.1937L10.2746 9.15028C10.3438 9.10414 10.4235 9.07598 10.5063 9.06838C10.5891 9.06077 10.6725 9.07394 10.749 9.10671L13.6763 10.3613C13.7757 10.4035 13.8588 10.4769 13.9128 10.5705C13.9669 10.664 13.9892 10.7726 13.9762 10.8798C13.8837 11.6038 13.5304 12.2693 12.9826 12.7516C12.4347 13.2339 11.7299 13.5 11 13.5C8.74566 13.5 6.58365 12.6045 4.98959 11.0104C3.39553 9.41635 2.5 7.25434 2.5 5C2.50004 4.27011 2.76612 3.56526 3.24843 3.01742C3.73073 2.46959 4.39618 2.11634 5.12019 2.02381C5.22745 2.01083 5.33602 2.03306 5.42955 2.08715C5.52307 2.14124 5.59649 2.22426 5.63873 2.3237L6.89439 5.25357C6.92687 5.32935 6.9401 5.41199 6.93291 5.49413C6.92572 5.57626 6.89833 5.65535 6.85318 5.72434L5.81341 7.31307C5.76608 7.38454 5.7381 7.46706 5.73221 7.55257C5.72631 7.63809 5.7427 7.72367 5.77977 7.80095V7.80095Z" stroke="#B2B5BF" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
)
const EnvelopeIconGrey = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
    <path d="M2 3.5H14V12C14 12.1326 13.9473 12.2598 13.8536 12.3536C13.7598 12.4473 13.6326 12.5 13.5 12.5H2.5C2.36739 12.5 2.24021 12.4473 2.14645 12.3536C2.05268 12.2598 2 12.1326 2 12V3.5Z" stroke="#B2B5BF" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M14 3.5L8 9L2 3.5" stroke="#B2B5BF" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
)
const VideoIcon =(props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80" fill="none" {...props}>
        <circle cx="40" cy="40" r="40" fill="white"/>
        <path d="M50 40L30 50V30L50 40Z" fill="#00114A"/>
    </svg>
)
const DotIcon = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
    <circle cx="9" cy="9" r="9" fill="#00114A"/>
    </svg>
)

const SearchIcon = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.16602 0.666672C3.12845 0.666672 0.666016 3.12911 0.666016 6.16667C0.666016 9.20424 3.12845 11.6667 6.16602 11.6667C7.50476 11.6667 8.7318 11.1884 9.68554 10.3933L13.4791 14.1869C13.6744 14.3822 13.991 14.3822 14.1862 14.1869C14.3815 13.9916 14.3815 13.675 14.1862 13.4798L10.3926 9.6862C11.1877 8.73245 11.666 7.50542 11.666 6.16667C11.666 3.12911 9.20358 0.666672 6.16602 0.666672ZM1.66602 6.16667C1.66602 3.68139 3.68073 1.66667 6.16602 1.66667C8.6513 1.66667 10.666 3.68139 10.666 6.16667C10.666 8.65195 8.6513 10.6667 6.16602 10.6667C3.68073 10.6667 1.66602 8.65195 1.66602 6.16667Z" fill="#4C505B"/>
    </svg>
)
const SortFilterIcon = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none" {...props}>
        <path d="M3.72656 6.5C3.31235 6.5 2.97656 6.83579 2.97656 7.25C2.97656 7.66421 3.31235 8 3.72656 8H22.2266C22.6408 8 22.9766 7.66421 22.9766 7.25C22.9766 6.83579 22.6408 6.5 22.2266 6.5H3.72656Z" fill="#00114A"/>
        <path d="M6.97656 12.25C6.97656 11.8358 7.31235 11.5 7.72656 11.5H18.2266C18.6408 11.5 18.9766 11.8358 18.9766 12.25C18.9766 12.6642 18.6408 13 18.2266 13H7.72656C7.31235 13 6.97656 12.6642 6.97656 12.25Z" fill="#00114A"/>
        <path d="M10.9766 17.1875C10.9766 16.7733 11.3123 16.4375 11.7266 16.4375H14.2266C14.6408 16.4375 14.9766 16.7733 14.9766 17.1875C14.9766 17.6017 14.6408 17.9375 14.2266 17.9375H11.7266C11.3123 17.9375 10.9766 17.6017 10.9766 17.1875Z" fill="#00114A"/>
    </svg>
)
const MoreFilterIcon = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none" {...props}>
    <path d="M8.99994 10.7496L2.99994 10.7496" stroke="#545F6F" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M13.9999 10.7496L10.9999 10.7496" stroke="#545F6F" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M4.99994 5.2496L2.99994 5.24953" stroke="#545F6F" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M13.9999 5.24953L6.99994 5.2496" stroke="#545F6F" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M6.99994 3.74956V6.74956" stroke="#545F6F" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M10.9999 12.2496V9.24957" stroke="#545F6F" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
)
const ArrowLeft = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
    <path d="M13.5 8L2.5 8" stroke="#B2B5BF" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M7 12.5L2.5 8L7 3.5" stroke="#B2B5BF" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
)
const ShareIcon = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
    <path d="M4 10C5.10457 10 6 9.10457 6 8C6 6.89543 5.10457 6 4 6C2.89543 6 2 6.89543 2 8C2 9.10457 2.89543 10 4 10Z" stroke="#B2B5BF" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M11 14.5C12.1046 14.5 13 13.6046 13 12.5C13 11.3954 12.1046 10.5 11 10.5C9.89543 10.5 9 11.3954 9 12.5C9 13.6046 9.89543 14.5 11 14.5Z" stroke="#B2B5BF" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M11 5.5C12.1046 5.5 13 4.60457 13 3.5C13 2.39543 12.1046 1.5 11 1.5C9.89543 1.5 9 2.39543 9 3.5C9 4.60457 9.89543 5.5 11 5.5Z" stroke="#B2B5BF" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M9.31782 4.58142L5.68213 6.91865" stroke="#B2B5BF" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M5.68213 9.0813L9.31782 11.4185" stroke="#B2B5BF" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
)
const BedIcon = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
    <path d="M0.625 18.125V3.125" stroke="#00114A" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M0.625 15.625H19.375" stroke="#00114A" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M0.625 11.875H19.375V18.125" stroke="#00114A" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M5 9.375C6.03553 9.375 6.875 8.53553 6.875 7.5C6.875 6.46447 6.03553 5.625 5 5.625C3.96447 5.625 3.125 6.46447 3.125 7.5C3.125 8.53553 3.96447 9.375 5 9.375Z" stroke="#00114A" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M19.375 9.375H9.375V5.625H16.875C17.538 5.625 18.1739 5.88839 18.6428 6.35723C19.1116 6.82607 19.375 7.46196 19.375 8.125V9.375Z" stroke="#00114A" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
)
const BathIcon = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
    <path d="M8.125 6.875C6.4674 6.875 4.87769 7.53348 3.70558 8.70558C2.53348 9.87769 1.875 11.4674 1.875 13.125H14.375C14.375 12.3042 14.2133 11.4915 13.8992 10.7332C13.5852 9.97494 13.1248 9.28595 12.5444 8.70558C11.9641 8.12522 11.2751 7.66484 10.5168 7.35075C9.75849 7.03666 8.94576 6.875 8.125 6.875Z" stroke="#00114A" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M8.125 6.875V5.625C8.125 4.29892 8.65178 3.02715 9.58947 2.08947C10.5271 1.15178 11.7989 0.625 13.125 0.625V0.625C14.4511 0.625 15.7229 1.15178 16.6605 2.08947C17.5982 3.02715 18.125 4.29892 18.125 5.625V19.375" stroke="#00114A" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M4.375 15.625V16.875" stroke="#00114A" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M8.125 18.125V19.375" stroke="#00114A" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M11.875 15.625V16.875" stroke="#00114A" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
)
const SizeIcon = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
    <path d="M19.375 1.875H0.625V18.125H19.375V1.875Z" stroke="#00114A" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M16.25 9.375V5H11.875" stroke="#00114A" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M8.125 15H3.75V10.625" stroke="#00114A" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
)
const HelperIcon = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
    <path d="M10.8335 6.24992C10.8335 6.71016 10.4604 7.08325 10.0002 7.08325C9.53992 7.08325 9.16683 6.71016 9.16683 6.24992C9.16683 5.78968 9.53992 5.41658 10.0002 5.41658C10.4604 5.41658 10.8335 5.78968 10.8335 6.24992Z" fill="#00114A"/>
    <path d="M8.3335 9.37492C8.3335 9.02974 8.61332 8.74992 8.9585 8.74992H10.2085C10.5537 8.74992 10.8335 9.02974 10.8335 9.37492V12.9166H11.4585C11.8037 12.9166 12.0835 13.1964 12.0835 13.5416C12.0835 13.8868 11.8037 14.1666 11.4585 14.1666H8.9585C8.61332 14.1666 8.3335 13.8868 8.3335 13.5416C8.3335 13.1964 8.61332 12.9166 8.9585 12.9166H9.5835V9.99992H8.9585C8.61332 9.99992 8.3335 9.7201 8.3335 9.37492Z" fill="#00114A"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.0002 0.833252C4.93755 0.833252 0.833496 4.93731 0.833496 9.99992C0.833496 15.0625 4.93755 19.1666 10.0002 19.1666C15.0628 19.1666 19.1668 15.0625 19.1668 9.99992C19.1668 4.93731 15.0628 0.833252 10.0002 0.833252ZM2.0835 9.99992C2.0835 5.62766 5.62791 2.08325 10.0002 2.08325C14.3724 2.08325 17.9168 5.62766 17.9168 9.99992C17.9168 14.3722 14.3724 17.9166 10.0002 17.9166C5.62791 17.9166 2.0835 14.3722 2.0835 9.99992Z" fill="#00114A"/>
    </svg>
)
const ExternalLinkIcon = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
    <path d="M10.3334 1.5C10.3334 1.22386 10.5573 1 10.8334 1H14.5001C14.7762 1 15.0001 1.22386 15.0001 1.5V5.16667C15.0001 5.44281 14.7762 5.66667 14.5001 5.66667C14.2239 5.66667 14.0001 5.44281 14.0001 5.16667V2.7071L9.85363 6.85355C9.65837 7.04881 9.34179 7.04881 9.14653 6.85355C8.95127 6.65829 8.95127 6.34171 9.14653 6.14644L13.293 2H10.8334C10.5573 2 10.3334 1.77614 10.3334 1.5Z" fill="#00114A"/>
    <path d="M1.66675 2.83333C1.66675 2.189 2.18908 1.66667 2.83341 1.66667H8.50008C8.77622 1.66667 9.00008 1.89052 9.00008 2.16667C9.00008 2.44281 8.77622 2.66667 8.50008 2.66667H2.83341C2.74137 2.66667 2.66675 2.74129 2.66675 2.83333V13.1667C2.66675 13.2587 2.74137 13.3333 2.83341 13.3333H13.1667C13.2588 13.3333 13.3334 13.2587 13.3334 13.1667V7.5C13.3334 7.22386 13.5573 7 13.8334 7C14.1096 7 14.3334 7.22386 14.3334 7.5V13.1667C14.3334 13.811 13.8111 14.3333 13.1667 14.3333H2.83341C2.18908 14.3333 1.66675 13.811 1.66675 13.1667V2.83333Z" fill="#00114A"/>
    </svg>
)
const PlayIcon = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="10" viewBox="0 0 8 10" fill="none" {...props}>
    <path d="M7.5 4.13397C8.16667 4.51887 8.16667 5.48113 7.5 5.86603L1.5 9.33013C0.833334 9.71503 0 9.2339 0 8.4641V1.5359C0 0.766098 0.833333 0.284973 1.5 0.669873L7.5 4.13397Z" fill="#00114A"/>
    </svg>
)
export {
    PhoneIconBlue,
    CallIconwhite,
    EnvelopeIconBlue,
    WhatsAppIconBlue,
    PhoneIconwhite,
    EnvelopeIconWhite,
    WhatsAppIconGreen,
    WhatsAppIconWhite,
    PhoneIconGrey,
    EmailIconWhite,
    EnvelopeIconGrey,
    VideoIcon,
    DotIcon,
    SearchIcon,
    SortFilterIcon,
    MoreFilterIcon,
    ArrowLeft,
    ShareIcon,
    BedIcon,
    BathIcon,
    SizeIcon,
    HelperIcon,
    ExternalLinkIcon,
    PlayIcon
}